<template>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="mb-4 text-center">
        <a href="/">
          <img src="../../assets/images/logo/logo_dark.png" class="img-fluid" />
        </a>
      </div>

      <div class="card">
        <div class="card-body">
          <notification-container />
          <BaseAlert v-if="error" :message="error" type="danger" />
          <form class="form" @submit.prevent="handlePasswordResetForm">
            <p>
              Entrez l'adresse e-mail vérifiée de votre compte utilisateur et
              nous vous enverrons un lien de réinitialisation du mot de passe.
            </p>
            <BaseInput
              label="Adresse e-mail"
              type="email"
              placeholder="Adresse email"
              v-model="email"
              :disabled="success"
              :isInvalid="$v.email.$error || errors.email !== undefined"
              @blur="$v.email.$touch()"
            >
              <template v-if="$v.email.$error || errors.email">
                <label class="small form-text text-danger text-right">
                  <em v-if="!$v.email.required">le champs e-mail est requis</em>
                  <em v-if="!$v.email.email"
                    >le champs e-mail n'est pas valide</em
                  >
                  <em v-if="errors.email">{{ errors.email[0] }}</em>
                </label>
              </template>
            </BaseInput>

            <BaseAlert v-if="success" :message="success" type="success" />

            <BaseButton
              :disabled="disabledSubmitBtn"
              button-class="btn-block mt-3 btn-secondary font-weight-bold"
              type="submit"
              :loading="loading"
              :showLoading="true"
            >
              Réinitialiser
            </BaseButton>
          </form>
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-body text-center">
          Retourner à la page de
          <router-link :to="{ name: 'Login' }">Connexion</router-link>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/layouts/Footer";
import { required, email } from "vuelidate/lib/validators";
import { recaptchaMixin } from "../../mixins/recaptchaMixin";
import NotificationContainer from "../../components/notification/NotificationContainer";
export default {
  name: "PasswordReset",
  mixins: [recaptchaMixin],
  components: { NotificationContainer, Footer },

  data() {
    return {
      loading: false,
      email: null,
      success: null,
      error: null,
      errors: []
    };
  },

  computed: {
    disabledSubmitBtn() {
      return (
        this.$v.$invalid ||
        this.loading ||
        this.success ||
        this.recaptchaToken === null
      );
    }
  },

  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`)
    };
  },

  methods: {
    handlePasswordResetForm() {
      if (!this.$v.invalid) {
        this.loading = true;
        this.$store
          .dispatch("auth/sendResetPasswordLink", {
            email: this.email,
            recaptcha: this.recaptchaToken
          })
          .then(message => {
            this.success = message;
            this.loading = false;
          })
          .catch(err => {
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
            if (err.response.data.message)
              this.error = err.response.data.message;
            this.loading = false;
            this.loadRecaptcha();
          });
      }
    }
  },

  validations: {
    email: { required, email }
  }
};
</script>

<style scoped></style>
